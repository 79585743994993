/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap'); /* Nunito para los botones */
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap'); /* Para los títulos (className title) */
@import url('https://fonts.googleapis.com/css2?family=Bellefair&display=swap'); /* Para el texto (className texto) */

/* GENERAL STYLES */

* {
	box-sizing: border-box;
}

html,
body {
	overflow-x: hidden !important;
}

body {
	margin: 0;
	padding: 0;
	/* padding-top: 72px; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* background-color: #F5F5F5;  */
	background-color: #ffffff;
	overflow-x: hidden !important;
}

.title {
	font-family: 'Kaushan Script', cursive;
	font-size: 35px;
	color: #11baaa;
}

.text {
	font-family: 'Bellefair', serif;
	font-size: 22px;
}

.subtitle {
	font-family: 'Bellefair', serif;
	font-size: 25px;
	color: #11baaa;
}

.title-orange {
	color: rgba(236, 75, 25, 0.8);
}

.subtitle-orange {
	color: rgba(236, 75, 25, 0.6);
	font-family: 'Kaushan Script', cursive;
}

.text-orange {
	color: rgb(236, 75, 25, 0.8);
	font-size: 24px;
}

.orange {
	color: rgba(225, 134, 27, 0.8);
}

.bg-orange {
	/* background-color: rgba(228, 187, 140, 0.2); */
	background-color: rgba(211, 177, 146, 0.1);
}

.vh-100 {
	height: 100vh !important;
}

.p-50 {
	padding: 50px;
}

.p-top-0 {
	padding-top: 0;
}

.p-bottom-60 {
	padding-bottom: 60px;
}

.text-right {
	text-align: right;
}

b,
strong {
	font-weight: normal;
	color: #11baaa !important;
}

.btn-circle {
	width: 50px;
	height: 50px;
	padding: 10px 10px;
	border-radius: 25px;
	font-size: 10px;
	text-align: center;
}

.btn-container {
	padding-right: 48px;
}

.btn-circle .svg-inline--fa {
	height: 2em;
}

.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh; /* Hace que el contenedor ocupe el 100% de la altura de la pantalla */
}

/* TS PARTICLES */

#tsparticles {
	/* This is the id of the div created by the Particles component */
	height: 700px;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0;
	padding: 0;
	z-index: 0;
}

.z-999 {
	z-index: 999;
}

#tsparticlesHeader {
	height: 72px;
}

/* HEADER OF VIEWS */

.parallax {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	/* display: flex; */
	background-color: rgba(12, 12, 12, 0.4);
	background-blend-mode: color;
	min-height: 480px;

	position: relative;
}

.parallax2 {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	/* display: flex; */
	background-color: rgba(12, 12, 12, 0.4);
	background-blend-mode: color;
	min-height: 200px;

	position: relative;
}

.parallax3 {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: rgba(12, 12, 12, 0.3);
	background-blend-mode: color;
	min-height: 400px;
	position: relative;
}

.caption-left {
	padding-bottom: 40px;
	padding-left: 10%;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;

	position: absolute;
	top: 50%;
}

.caption-left ul {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 0;
	font-size: 1em;
	letter-spacing: 5px;
	/* background-color: transparent;
  color: rgba(105, 105, 105, 0.6); */
	color: rgba(255, 255, 255, 0.6);
	font-family: 'Nunito Sans', sans-serif !important;
}

.caption-left a {
	text-decoration: none;
	color: rgba(255, 255, 255, 0.4);
}

.caption-left a:hover {
	color: rgba(255, 255, 255, 0.6);
}

.caption-left li:first-child {
	padding-right: 10px;
}

.caption-left li:last-child {
	padding-right: 10px;
	padding-left: 10px;
	color: rgba(255, 255, 255, 0.6);
}

.caption-left h1 {
	color: rgba(255, 255, 255, 0.6);
	/* font-family: 'Nunito Sans', sans-serif !important; */
	font-family: 'Bellefair', serif !important;
	letter-spacing: 4px;
	font-size: 35px;
}

/* MASAJES PAGE */
.masajes-title {
	font-family: 'Bellefair', serif;
	font-size: 28px;
	color: #895220;
	letter-spacing: 1px;
}

.masajes-container {
	width: 80%;
	margin: auto;
}

.msj-img {
	/* width: 400px; */
	width: 40%;
	/* height: 300px; */
	margin-bottom: 40px;
	/* background-color: grey; */
}

.msj-img img {
	width: 100%;
}
.msj-text {
	width: 55%;
	font-size: 22px;
}

.pl-30 {
	padding-left: 30px;
}

.pe-30 {
	padding-right: 30px;
}

.text-list {
	font-size: 18px;
}

/* MEDIA QUERYS */
@media (max-width: 1500px) {
	/*  HEADER */
	.header-img img {
		width: 450px;
	}
}

@media (max-width: 1400px) {
	.spa-container {
		position: absolute;
		top: 0;
		right: 41% !important;
	}
}

@media (max-width: 1260px) {
	/*  HEADER */
	.header-img img {
		width: 400px;
	}
}

@media (max-width: 1200px) {
	/*  HEADER */
	.header-img img {
		width: 400px;
	}

	.p-bottom-60 {
		padding-bottom: 30px;
	}

	.btn-container {
		padding-right: 30px;
	}

	/* ABOUT MAIN PAGE */
	.about-cont {
		flex-direction: row-reverse !important;
		padding-bottom: 0 !important;
		margin-bottom: 0 !important;
	}
	.about-img img {
		max-width: 350px;
	}
	.about-text {
		padding-bottom: 0 !important;
	}
	.aboutBtns-container {
		display: none;
	}
	.aboutBtns-container-1200 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	/* MASAJES MAIN PAGE */
	.masajes-mainpage-img img {
		width: 400px;
		padding-top: 10px;
	}

	.masajes-mainpage-content {
		width: 90%;
	}
}

@media (max-width: 1150px) {
	.header-img img {
		width: 380px;
	}
}

@media (max-width: 1070px) {
	.header .btn-container {
		padding-right: 10px;
	}
	.header-img img {
		width: 370px;
	}
}

@media (max-width: 992px) {
	/* NAVBAR */
	.navbar-collapse-bg {
		background-color: rgba(84, 84, 84, 0.8) !important;
		border-radius: 4%;
	}

	.navbar-dropdown-bg .first {
		padding-top: 20px !important;
	}

	.navbar-dropdown-bg .last {
		padding-bottom: 20px !important;
	}

	.navbar-container {
		/* width: 90% !important;   */
	}

	/*  HEADER */

	.header-btns {
		/* display: none !important; */
		width: 100% !important;
		justify-content: space-between;
	}

	.header-btns .btn {
		font-size: 13px !important;
	}

	.header .btn-container {
		padding: 10px;
		padding-bottom: 0px;
	}

	.header {
		height: 102vh !important;
	}

	#tsparticles {
		height: 102vh !important;
	}

	.header .content {
		width: 70% !important;
		flex-direction: column !important;
		text-align: center;
	}

	.header .title {
		padding-bottom: 15px !important;
		padding-top: 20px !important;
	}

	.header .text {
		width: 80%;
		margin: auto;
		font-size: 22px !important;
	}

	.header-img {
		align-items: center;
		margin: auto;
	}

	.header .container {
		padding-right: 0 !important;
		padding-left: 0 !important;
		max-width: 800px;
		width: 80%;
	}

	.header .p-bottom-60 {
		padding-bottom: 10px;
	}

	.header .btn-container {
		padding-right: 10px;
	}

	/*  ABOUT MAIN PAGE */
	.about-cont {
		flex-direction: column-reverse !important;
		align-items: center;
	}

	.about-cont .title {
		text-align: center;
	}
	.about-cont .subtitle {
		text-align: center;
	}
	.about-cont .text {
		text-align: center;
	}
	.p-50 {
		padding: 0;
	}

	.aboutBtns-container-1200 {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	/* MASAJES MAIN PAGE */
	.masajes-mainpage-content {
		flex-direction: column-reverse !important;
		align-items: center;
		padding-top: 10px !important;
	}

	.spa-container {
		right: 37% !important;
	}

	.masajes-mainpage-img img {
		width: 350px;
	}

	/*MASAJES PAGE */
	.masajes-container .flex-row {
		flex-direction: column !important;
		align-items: center;
		height: 100% !important;
	}

	.msj-img {
		/* width: 400px; */
		width: 80% !important;
		/* height: 300px; */
		margin-bottom: 30px !important;
	}

	.msj-text {
		width: 90% !important;
		font-size: 22px;
	}

	.masajes-container .flex-row-reverse {
		flex-direction: column !important;
		align-items: center;
		height: 100% !important;
	}

	/* TERAPIAS PAGE */
	.text-list ol {
		width: 100% !important;
	}
}

@media (max-width: 768px) {
	/* GENERAL */
	.title {
		font-size: 33px;
	}

	.subtitle {
		font-size: 23px;
	}
	/* HEADER */
	.header .text {
		width: 90%;
		font-size: 20px !important;
	}

	.header-img {
		padding-top: 10px;
	}

	.header-img img {
		width: 350px !important;
	}

	/* ABOUT MAINPAGE*/
	.about-img {
		width: initial;
	}

	.about-img img {
		width: 300px;
	}

	/* MASAJES MAIN PAGE */
	.spa-container {
		right: 30% !important;
	}

	.masajes-mainpage-img img {
		width: 300px;
	}
}

@media (max-width: 670px) {
	.header .text {
		width: 100%;
	}
	.header {
		height: 103vh !important;
	}
	#tsparticles {
		height: 103vh !important;
	}
	.header-img {
		padding-top: 20px;
	}
	.content {
		padding-top: 20px !important;
	}
}

@media (max-width: 617px) {
	.header-img img {
		width: 350px;
	}
}

@media (max-width: 540px) {
	/* HEADER & NAVBAR */
	.header-img img {
		width: 320px !important;
	}
	.header {
		height: 102vh !important;
	}
	#tsparticles {
		height: 102vh !important;
	}
}

@media (max-width: 475px) {
	/* HEADER & NAVBAR */
	.header .content {
		width: 85% !important;
	}

	.header-title {
		margin-top: 30px !important;
	}

	.header-btns .btn {
		font-size: 12px !important;
	}
	.btn-container {
		padding: 20px;
	}
}

@media (max-width: 445px) {
	/* HEADER & NAVBAR */
	.header-img img {
		width: 270px !important;
	}
}

@media (max-width: 425px) {
	/* HEADER & NAVBAR */
	.navbar-img {
		width: 55px !important;
	}

	/* GENERAL */
	.subtitle {
		font-size: 20px;
	}

	.title {
		font-size: 32px;
	}

	/* MASAJES */
	.masajes-title {
		font-size: 24px;
	}

	.msj-subtitle {
		padding-bottom: 0px !important;
	}

	.spa-container {
		right: 24% !important;
	}

	/* MASAJES MAIN PAGE */
	.masajes-mainpage-img img {
		width: 260px;
	}

	/* TERAPIAS PAGES */
	.masajes-container {
		width: 90%;
	}

	/* MASAJES PAGE */
	.masajes-container-masajes {
		width: 80% !important;
	}
}

@media (max-width: 375px) {
	/* GENERAL */
	.subtitle {
		font-size: 18px;
	}

	.title {
		font-size: 30px;
	}

	.text {
		font-size: 19px;
	}

	/* ABOUT */
	.about-img img {
		width: 260px;
	}

	/* MASAJES MAIN PAGE */
	.masajes-mainpage-img img {
		width: 260px;
	}

	.masajes-mainpage-content ul {
		padding-left: 0rem !important;
	}
}
