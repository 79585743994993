
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellefair&display=swap');

.nav-link {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 18px !important;
    padding: 10px !important;
}


/* create variables */
$gissel-color : rgb(17, 186, 170);

/* change variables */
$navbar-light-color:                #AAB4B3;
$navbar-light-hover-color:          $gissel-color;
$navbar-light-active-color:         $gissel-color;

$primary: $gissel-color;
$danger: rgba(236, 75, 25, .6);
$btn-font-family: 'Nunito Sans', sans-serif;


.btn-primary { 
    color: #FFFFFF !important;
}

.btn-secondary { 
    color: #AAB4B3 !important;
}

.btn {
    font-size: 14px !important;
    letter-spacing: .8px;
    --bs-btn-padding-y: 10px !important;
    --bs-btn-padding-x: 2.5em !important;
}

.btn-outline-primary {
    --bs-btn-hover-color: #FFFFFF !important;
    --bs-btn-active-color: #FFFFFF !important;
}
  

.navbar-toggler {
    border: none !important;
}

.navbar-toggler-icon {
    color: $gissel-color !important;
}

.navbar {
    background-color: transparent;
    border-radius: 10px;
    padding: 0 !important;
    margin: auto;
    width: 80%;
    top: 2% !important;
}


.navbar .nav-link {
    color: rgba(255, 255, 255, 0.6);
}

.navbar-bg {
    background-color: #FFFFFF;
    /* box-shadow: 2px 2px 2px 0 rgba(0,0,0,.2); */
    box-shadow: 0.5px 1px 1px 2px rgba(0,0,0,.2); 
}

.navbar-bg .nav-link{
    color: #aab4b3;
}


.navbar-collapse {
    flex-grow: 0 !important;
    /* margin-top: 10px; */
    /* border-radius: 5%; */
}


nav .container {
    padding: 0 25px !important;
}
 

.navbar-brand .nav-link {
    padding: 0 !important;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding-right: calc(var(--bs-gutter-x) * 1) !important;
    padding-left: calc(var(--bs-gutter-x) * 1) !important;
}



.dropdown-item {
    color:  #aab4b3 !important;
    /* color: rgb(255, 255, 255,.5) */
    padding: .5rem 1rem !important;
}
.dropdown-item:hover {
    color: $gissel-color !important;
}

.navbar-dropdown-bg .dropdown-menu {
    --bs-dropdown-bg: rgba(84, 84, 84,.8) !important;
    --bs-dropdown-link-hover-bg: rgba(84, 84, 84,.8) !important;
    --bs-dropdown-link-hover-color: $gissel-color !important;
}

.navbar-dropdown-bg  .navbar-collapse {
    margin-top: 15px;
}

.navbar-dropdown-bg .nav-link {
    padding-left: 25px !important;
}


/* import bootstrap style at the bottom */
@import '~bootstrap/scss/bootstrap.scss';