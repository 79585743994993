/* ABOUT MAIN PAGE */
.about-img {
	/* width: 50%; */
	padding-top: 20px;
}

.about-img img {
	max-width: 400px;
	border-radius: 10%;
}

.about-text {
	position: relative;
}

.about-cont {
	position: relative;
}

.hand-container {
	position: absolute;
	top: 0;
	right: 30%;
}

.hand-container .svg-inline--fa {
	height: 14em;
}

.aboutBtns {
	width: 200px;
	margin: auto;
}

.aboutBtns-container-1200 {
	display: none;
}

/* MEDIA QUERYS */

@media (max-width: 1400px) {
	.spa-container {
		position: absolute;
		top: 0;
		right: 41%;
	}

	/* ABOUT PAGE */
	.about-page-img {
		width: 50% !important;
	}

	.about-page-p1 {
		width: 45% !important;
	}

	.about-page-p2 {
		width: 45% !important;
	}
}

@media (max-width: 1200px) {
	/* ABOUT MAIN PAGE */
	.about-cont {
		flex-direction: row-reverse !important;
		padding-bottom: 0 !important;
		margin-bottom: 0 !important;
	}
	.about-img img {
		max-width: 350px;
	}
	.about-text {
		padding-bottom: 0 !important;
	}
	.aboutBtns-container {
		display: none;
	}
	.aboutBtns-container-1200 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 992px) {
	/*  ABOUT MAIN PAGE */
	.about-cont {
		flex-direction: column-reverse !important;
		align-items: center;
	}

	.about-cont .title {
		text-align: center;
	}
	.about-cont .subtitle {
		text-align: center;
	}
	.about-cont .text {
		text-align: center;
	}
	.p-50 {
		padding: 0;
	}

	.aboutBtns-container-1200 {
		margin-top: 25px;
		margin-bottom: 25px;
	}
}

.about-a:hover {
	color: #ffffff;
}
